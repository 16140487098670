<template>
  <div class="editAddress_content">
    <!-- <van-address-edit
      :address-info="AddressInfo"
      :area-list="areaList"
      show-set-default
      @save="onSave"
    />-->
    <van-cell-group>
      <van-field v-model="AddressInfo.receiverName" label="收货人" placeholder="请输入收货人" />
    </van-cell-group>
    <van-cell-group>
      <van-field v-model="AddressInfo.mobile" label="手机号" placeholder="请输入手机号" />
    </van-cell-group>
    <van-cell-group>
      <!-- <van-cell title="地区" @click="ondirl" :value="areaCell" /> -->
      <div class="diququ">地区</div>
      <van-cell is-link @click="showPopup">{{ areaCell }}</van-cell>
      <van-popup v-model="popShow" position="bottom" :style="{ height: '60%' }">
        <van-tabs v-model="active">
          <van-tab :title="Provincelistswww">
            <div v-for="(item) in Provincelists" :key="item.id" class="ads_item" @click="addressChagea(item)">
              {{ item.name }}
            </div>
          </van-tab>
          <van-tab :title="Citylistwww">
            <div v-for="(item) in Citylist" :key="item.id" class="ads_item" @click="addressChageb(item)">
              {{ item.name }}
            </div>
          </van-tab>
          <van-tab :title="Arealistwww">
            <div v-for="(item) in Arealist" :key="item.id" class="ads_item" @click="addressChagec(item)">
              {{ item.name }}
            </div>
          </van-tab>
        </van-tabs>
      </van-popup>
    </van-cell-group>
    <van-cell-group>
      <van-field
        v-model="AddressInfo.address"
        type="textarea"
        label="详细地址"
        placeholder="请输入详细地址"
        rows="1"
        autosize
      />
    </van-cell-group>

    <van-cell center title="设为默认收货地址">
      <template #right-icon>
        <!--        <van-switch v-model="AddressInfo.isDefault" size="24" />-->
        <van-switch v-model="addResIdChecked" size="24" />
      </template>
    </van-cell>
    <van-button style="margin-top:20px;" round type="danger" size="large" @click="onSave">保存地址</van-button>
  </div>
</template>

<script>
import Vue from 'vue'
import { AddressEdit, Area, Button, Cell, CellGroup, Field, Popup, Switch, Tab, Tabs, Toast } from 'vant'

Vue.use(AddressEdit)
Vue.use(Toast)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Area)
Vue.use(Switch)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
import province_list from '../../utils/areaList'
import { addressH5Create, getProvince, getCity, getArea } from '@/services/userApi'

export default {
  data() {
    return {
      active: 0,
      username: '',
      phone: '',
      areaCell: '选择省/市/地区',
      detailArea: '',
      show: false,
      popShow: false,
      checked: false,
      areaList: province_list,
      searchResult: [],
      Provincelists: '', // 省
      Citylist: '', // 市
      Arealist: '', // 区
      Provincelistswww: '请选择', // 省
      Citylistwww: '请选择', // 市
      Arealistwww: '请选择', // 区
      Provincelistsddd: '请选择', // 省
      Citylistddd: '请选择', // 市
      Arealistddd: '请选择', // 区
      AddressInfo: {
        receiverName: '',
        address: '',
        addressId: '',
        mobile: '',
        townId: '',
        townName: '',
        proviceId: '',
        proviceName: '',
        cityId: '',
        cityName: '',
        zoneId: '',
        zoneName: '',
        isDefault: false,
        mid: localStorage.getItem('uid')
      },
      addResIdChecked: false // 置顶
    }
  },

  mounted() {
    const _list = this.$route.query
    this.addResIdChecked = this.$route.query.isDefault !== '0'
    if (_list) {
      this.AddressInfo = {
        receiverName: _list.name,
        address: _list.house,
        addressId: _list.id,
        mobile: _list.tel,
        townId: _list.townId,
        townName: '',
        proviceId: _list.proviceId,
        proviceName: _list.proviceName,
        cityId: _list.cityId,
        cityName: _list.cityName,
        zoneId: _list.zoneId,
        zoneName: _list.zoneName,
        isDefault: !!(_list.isDefault && _list.isDefault !== '0')
      }
      this.areaCell = _list.proviceName
        ? _list.proviceName + _list.cityName + _list.zoneName
        : '选择省/市/地区'
    }
  },

  methods: {
    areaBtn() {
      this.areaCell = this.Provincelistswww + ' ' + this.Citylistwww + ' ' + this.Arealistwww
      this.AddressInfo.proviceName = this.Provincelistswww
      this.AddressInfo.proviceId = this.Provincelistsddd
      this.AddressInfo.cityName = this.Citylistwww
      this.AddressInfo.cityId = this.Citylistddd
      this.AddressInfo.zoneName = this.Arealistwww
      this.AddressInfo.zoneId = this.Arealistddd
    },
    onSave() {
      const _self = this
      const _fdata = _self.AddressInfo
      const _uid = localStorage.getItem('uid')
      const params = {
        address: _fdata.address, // 详情地址
        addressId: this.$route.query.addressId, //
        cityId: _fdata.cityId, //
        cityName: _fdata.cityName, // 区
        isDefault: _self.addResIdChecked ? 1 : 0, // 0 1   // 设置默认地址
        mobile: _fdata.mobile, // 手机号
        proviceId: _fdata.proviceId, // 2
        proviceName: _fdata.proviceName, // 市
        receiverName: _fdata.receiverName, // 账号名字
        townId: '', //
        townName: '', //
        zoneId: _fdata.zoneId,
        zoneName: _fdata.zoneName, // 乡
        mid: _uid //
      }
      addressH5Create(params).then((res) => {
        if (Number(res.status) === 200) {
          Toast('操作成功')
          this.$router.go(-1)
        } else {
          Toast('操作失败')
        }
      })
    },
    // 查询省
    getProvincelist() {
      getProvince({}).then(res => {
        if (Number(res.code) === 200) {
          this.Provincelists = res.data
        } else {
          Toast(res.msg)
        }
      })
    },
    // 查询市
    getCitylist(id) {
      const params = {
        province_id: id,
        page: 1,
        pageSize: 999
      }
      getCity(params).then(res => {
        if (Number(res.code) === 200) {
          this.Citylist = res.data
        } else {
          Toast(res.msg)
        }
      })
    },
    // 查询区
    getArealist(id) {
      const params = {
        city_id: id,
        page: 1,
        pageSize: 999
      }
      getArea(params).then(res => {
        if (Number(res.code) === 200) {
          this.Arealist = res.data
        } else {
          Toast(res.msg)
        }
      })
    },
    // 选择省
    addressChagea(item) {
      this.active = 1
      this.Provincelistswww = item.name// 省
      this.Provincelistsddd = item.id// 省
      this.getCitylist(item.id)
    },
    // 选择市
    addressChageb(item) {
      this.active = 2
      this.Citylistwww = item.name// 市
      this.Citylistddd = item.id// 市
      this.getArealist(item.id)
    },
    // 选择区
    addressChagec(item) {
      this.Arealistwww = item.name// 区
      this.Arealistddd = item.id// 区
      // this.areaCell = this.Provincelistswww+this.Citylistwww+item.name;
      this.areaBtn()
      this.popShow = false
    },
    showPopup() {
      this.getProvincelist()
      this.popShow = true
    },
    onDelete() {
      Toast('delete')
    }
  }
}
</script>

<style lang="less" scoped>
.editAddress_content {
  padding: 0 10px;

  .diququ {
    padding-left: 18px;
    font-size: 16px;
    margin-right: 10px;
    text-align: left;
    width: 120px;
    color: #646566;
  }

  .ads_item {
    width: 100%;
    line-height: 40px;
    font-size: 18px;
    text-align: left;
    padding-left: 50px;
  }
}

//强改
/deep/ .van-cell {
  font-size: 15px;
}

/deep/ .van-cell__title {
  text-align: left;
}

/deep/ .van-cell__value {
  text-align: left;
  position: relative;
  overflow: hidden;
  color: #969799;
  vertical-align: middle;
  word-wrap: break-word;
  flex: 2;
}

/deep/ .van-cell-group {
  display: flex;
  align-items: center;
}

/deep/ .van-tab__pane {
  height: 350px;
  overflow-y: scroll;
}
</style>
